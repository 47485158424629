import "core-js/modules/es.array.push.js";
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import BindUser from './bindUser';
import { mixins } from "@/plugins/mixins";
export default {
  name: "Dept",
  components: {
    BindUser
  },
  mixins: [mixins],
  data() {
    return {
      currentDept: null,
      bindUserDialog: false,
      columns: [{
        label: '部门编号',
        prop: 'deptCode'
      }, {
        label: '部门名称',
        prop: 'deptName'
      }],
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.deptAdd,
        permissions: ['dept_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'dept_del',
        click: this.deptDel,
        permissions: ['dept_del']
      }, {
        name: '修改',
        code: 'dept_edit',
        click: this.deptEdit,
        permissions: ['dept_edit']
      }, {
        name: '绑定用户',
        code: 'dept_bindUser',
        click: this.deptBindUser,
        permissions: ['dept_bind_user']
      }],
      searchItems: []
    };
  },
  methods: {
    deptAdd() {
      this.$router.push('/deptAdd');
    },
    deptEdit(row) {
      this.$router.push({
        path: '/deptAdd',
        query: {
          deptId: row.deptId
        }
      });
    },
    deptDel(row) {
      ElMessageBox.confirm('确认要删除该部门吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.uac.dept.del(row.deptId).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    deptBindUser(row) {
      this.currentDept = row;
      this.bindUserDialog = true;
    },
    doBindUser(deptId, userIds) {
      const loading = ElLoading.service();
      this.$api.uac.dept.bindUser(deptId, userIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindUserDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};